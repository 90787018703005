<template>
    <div>商品推广</div>
</template>

<script>
    export default {
        name: "GoodsPromote"
    }
</script>

<style scoped>

</style>